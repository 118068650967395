import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ServiceTemplate from "./ServiceTemplate";
import './ServiceTemplate.css'
// import ServiceTemplate from "../components/ServiceTemplate";
const ServiceDetails = () => {
  const { serviceName } = useParams();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/${serviceName}`);
        setServiceData(response.data);
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    };
    fetchServiceData();
  }, [serviceName]);

  if (!serviceData) {
    return (
      <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>
    );
  }

  return (
    <ServiceTemplate
      headerSection={serviceData.headerSection}
      timelineItems={serviceData.timelineItems}
      coreServices={serviceData.coreServices}
      features={serviceData.features}
      title={serviceData.title}
      tagline={serviceData.tagline}
      icons={serviceData.icons}
      featureimg={serviceData.featureimg}
      streamlinedesc={serviceData.streamlinedesc}
    />
  );
};

export default ServiceDetails;
