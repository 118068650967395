import React, { useState, useEffect } from "react";
import "./ProjectTemplate.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";
import { Autoplay, Scrollbar } from "swiper/modules";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';

const ProjectTemplate = () => {
    const [project, setProject] = useState(null);
    const [error, setError] = useState(null);

    const params = useParams();  // Access URL params (e.g., project title)
    const projectName = params.name;
    console.log("Project name from URL:", projectName);
    useEffect(() => {
        // Fetch project data from the backend
        const fetchProject = async () => {
            try {
                // Make an API call to the backend
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectName}`);
                console.log('Fetched Project Data:', response.data);
                setProject(response.data); // Set the project data in state

            } catch (err) {
                setError("Error fetching project data.");
                console.error(err);
            }
        };

        if (projectName) {
            fetchProject();
        }
    }, [projectName]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!project) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        );
    }


    return (
        <div className="bg-project">
            <div className="container-fluid">
                {/* Project Title */}
                <h3 className="custom-font project-title text-center p-lg-5 p-2 pt-5 ">
                    {project.title}
                </h3>
                <h1 className="custom-font project-title text-center" style={{ fontSize: "4rem" }}>
                    {project.subtitle}
                </h1>
                <p className="text-center" style={{ margin: "40px auto", fontSize: "26px" }}>
                    {project.description}
                </p>

                {/* Client & Context */}
                <div className="container about-project-container p-3 mt-4 mb-5">
                    <h2>Client & Context</h2>
                    <hr className="project-underline" />
                    <div className="row justify-content-center ">
                        <div className="col-md-9">
                            <div className="d-flex flex-row  ">
                                <div className="d-flex flex-column text-end about-project-heading ">
                                    {/* <h4>Client</h4>
                                    <h4>Services Provided</h4> */}
                                    <h4>Services Provided</h4>
                                    <h4>Project Scope</h4>
                                    <h4 >Industry Domain</h4>
                                </div>
                                <div className="border-separator"></div>
                                <div className="d-flex flex-column text-start" style={{ gap: 4, marginTop: -1 }}>
                                    {/* <h5>{project.clientDetails.name}</h5> */}
                                    <h5>{project.clientDetails.servicesProvided}</h5>
                                    <h5>{project.clientDetails.projectScope}</h5>
                                    <h5>{project.clientDetails.industryDomain}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Problem Statement */}
                <div className="container project-problem-container p-3 mt-5 mb-5">
                    <h2 style={{ fontWeight: "bold", fontSize: 35 }} className="custom-font">Problem Statement</h2>
                    <hr className="project-underline" />
                    <p style={{ color: "#white", marginTop: 15, fontSize: "1.4rem" }}>
                        {project.problemStatement}
                    </p>
                </div>

                <div className="container project-problem-container p-3 mt-4 mb-5">
                    <h2 style={{ fontWeight: "bold", fontSize: 35 }} className="custom-font">The Solution</h2>
                    <hr className="project-underline" />
                    <div className="row justify-content-center ">
                        <ul className="feature-list">
                            {project.solution.map((solution, index) => (
                                <li key={index} className="feature-item">{solution}</li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Technologies Used */}
                <div className="container technologies-container p-3 mt-3 mb-5">
                    <h2 style={{ fontWeight: "bold", fontSize: 35 }} className="custom-font">Technologies Used</h2>
                    <hr className="project-underline" />
                    <div className="technologies-grid" >
                        {project.technologies.map((tech, index) => (
                            <div className="technology-item" key={index} >
                                <img src={`/${tech.image}`} alt={tech.name} className="tech-image" />
                                <h3 className="tech-name">{tech.name}</h3>
                                <p className="tech-role">{tech.role}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* System Interface & Working */}
                <div className="container p-3 mt-4 mb-5">
                    <h2 style={{ fontWeight: "bold", fontSize: 38 }} className="custom-font">System Interface & Working</h2>
                    <hr className="project-underline" />
                    <div className="project-swiper-container">
                        <Swiper
                            scrollbar={{ hide: true }}
                            modules={[Autoplay, Scrollbar]}
                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                            centeredSlides={true}
                            className="mySwiper projectSwiper"
                        >
                            {project.systemInterfaces?.map((item, index) => (
                                <SwiperSlide key={index} className="project-slider">
                                    {/* Image at the top */}
                                    <img
                                        src={`/${item.image}`}
                                        alt={`Slide ${index + 1}`}
                                        className="project-slider-image"
                                    />
                                    {/* Caption at the bottom */}
                                    {item.caption && (
                                        <p className="project-slider-caption">
                                            {item.caption}
                                        </p>
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                {/* Roles & Responsibilities */}
                <div className="container roles-container p-3 mt-4 mb-5">
                    <h2 style={{ fontWeight: "bold", fontSize: 35 }} className="custom-font">Our Roles</h2>
                    <hr className="project-underline" />
                    <div className="roles-grid">
                        {project.roles.map((role, index) => (
                            <li key={index} className="role-item">{role}</li>
                        ))}
                    </div>
                </div>
                {/* Achievements */}
                <div className="container achievements-container mt-5">
                    <h2 style={{ fontWeight: "bold", fontSize: 38 }} className="custom-font">Achievements</h2>
                    <hr className="project-underline" />
                    <div className="achievements-grid">
                        {project.achievements.map((achievement, index) => (
                            <div className="achievement-item" key={index}>
                                <h5 className="achievement-value">{achievement.value}</h5>
                                <p className="achievement-description">{achievement.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="heading-streamline text-center p-lg-5 pt-lg-5">
                    <h1 className="custom-font " style={{ marginTop: 70 }}>Ready to Transform Your Vision into Reality?</h1>
                    <h6>Let's Build Something Amazing <span className="fw-bolder  " style={{ color: "#052f3f" }}>Together</span></h6>
                    <Link to={"/contact"}>  <button className="collaborate-btn mb-5 " style={{ width: 300 }}><span >Interested in Similar Solutions</span></button></Link>
                </div>
            </div>
        </div>
    );
}
export default ProjectTemplate;