import React from "react";
import '../fonts.css';
import logo from "../assets/AmblemwithBG.svg";
import { Link } from "react-router-dom"
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { IoStarSharp } from "react-icons/io5";

import "./Footer.css";

function Footer() {
  const scheduling = 4.5;
  const cost = 4.5;
  const quality = 5;
  const recomend = 5;

 

  return (
    <>
        <div className="row pt-5 footer  ">
          <div className="col-lg-4 col-md-6 col-sm-6 ps-lg-5 pe-5  d-flex flex-column align-items-start">
            <div className="d-flex align-items-start">
              <img src={logo} width={100} className="m-2" alt="Neksogix Logo" />
              <p className="ms-2 mt-2 text-white">
                <span className="fw-bold">Neksogix:</span> Your go-to experts in
                Python, machine learning, and AI for groundbreaking digital
                transformation and growth.
              </p>
            </div>

            <div className="pt-5 pb-5 ms-2">
              <ul
                style={{ listStyle: "none", color: "white", paddingLeft: "0" }}
              >
                <li className="d-flex align-items-center mb-2">
                  <IoIosCall style={{ fontSize: "30px" }} />
                  <p className="mb-0 ms-2">+92 304 4181616</p>
                </li>
                <li className="d-flex align-items-center mb-2">
                  <IoIosMail style={{ fontSize: "30px" }} />
                  <p className="mb-0 ms-2">neksogix_solutions@neksogix.com</p>
                </li>
                <li className="d-flex align-items-center">
                  <IoLocationSharp style={{ fontSize: "30px" }} />
                  <p className="mb-0 ms-2">Austin, Texas</p>
                </li>
              </ul>
            </div>

            <div
              className=" d-flex align-items-center gap-3 pb-5 ms-2  "
              style={{ color: "white" }}
            >
              <Link to={"https://www.facebook.com/profile.php?id=61558310014555&mibextid=ZbWKwL"} target="blank" ><FaFacebook size={30} className="fa-fb fa-icons" /></Link>
              <Link to={"https://www.instagram.com/neksogix?igsh=MTBsb3I4c2N5aXBvOA=="} target="blank"><FaInstagram size={30} className="fa-instagram fa-icons" /></Link>
              <Link to={"https://www.linkedin.com/company/neksogix-it/"} target="blank"><FaLinkedin size={30} className="fa-linkedin fa-icons" /></Link>
            </div>
          </div>
 
          <div className="col-lg-2 col-md-6 col-sm-6 d-md-flex justify-content-md-center d-sm-flex justify-content-sm-center pt-3 ps-0 text-white">
            <ul style={{ listStyle: "none" }}>
              <li className="fw-bold pb-2 fs-5">Services</li>
              <li><Link to={'/services/artificial-intelligence'} className="footer-link">Artificial Intelligence</Link></li>
              <li><Link to={'/services/machine-learning'} className="footer-link">Machine Learning</Link></li>
              <li><Link to={'/services/web-scraping'} className="footer-link">Web Scraping</Link></li>
              <li><Link to={'/services/web-development'} className="footer-link">Web Development</Link></li>
              <li><Link to={'services/database-management'} className="footer-link">Database Management</Link></li>
              <li><Link to={'/services/python-scripting'} className="footer-link">Python Scripting</Link></li>
              <li><Link to={'services/application-reengineering'} className="footer-link">Application Re-Engineering</Link></li>
              <li><Link to={'services/custom-software-development'} className="footer-link">Custom Software Development</Link></li>
              <li><Link to={'/services/data-analysis'}className="footer-link">Data Analysis/Visualization</Link></li>
              {/* <li><Link to={'/services/data-visualization'} className="footer-link">Data Visualization</Link></li> */}
              {/* <li><Link to={'/services/web-development'} className="footer-link">Web Development</Link></li> */}
              <li><Link to={'/services/ecommerce-store'} className="footer-link">Ecommerce Store</Link></li>
              <li><Link to={'/services/backend-development'} className="footer-link">Backend Development</Link></li>
              {/* <li><Link to={'/services/data-mining'} className="footer-link">Data Mining</Link></li> */}
            </ul>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-6 pt-3 ps-0 text-white">
            <ul style={{ listStyle: "none" }}>
              <li className="fw-bold fs-5 pb-2">Industries</li>
              <li><a href="#design" className="footer-link">Amazon Web Services</a></li>
              <li><a href="#prototyping" className="footer-link">Real Estate</a></li>
              <li><a href="#development-features" className="footer-link">E-Education</a></li>
              <li><a href="#design-systems" className="footer-link">Fin Tech</a></li>
              <li><a href="#collaboration-features" className="footer-link">Health Care</a></li>
              <li><a href="#design-process" className="footer-link">Supply Chain</a></li>
              <li><a href="#figjam" className="footer-link">GPT Family</a></li>
              <li><a href="#figjam" className="footer-link">Lamma Family</a></li>
            </ul>
          </div>
          <div className="col-lg-4 col-sm-6 col-md-6 p-0  ">
            <div className="review-card pt-5 pb-5 mt-3 ">
              <div className="review-title">
                <img src={logo} width={35} className="border" alt="logo" />
                <h4 className="text-white  ">NEKSOGIX REVIEWS</h4>
              </div>
              <div className="rating-container mt-3">
                <h5 className="rating-number">5.0</h5>
                <div className="stars d-flex align-items-center fs-5">
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                </div>
              </div>
              <div className="rating-text pt-2">
                <p className="text-start">Scheduling</p>
                <p >{scheduling}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${scheduling * 20}%` }}
                ></div>
              </div>
              <div className="rating-text pt-2">
                <p>Quality</p>
                <p>{quality}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${quality * 20}%` }}
                ></div>
              </div>
              <div className="rating-text pt-2">
                <p>Cost</p>
                <p>{cost}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${cost * 20}%` }}
                ></div>
              </div>
              <div className="rating-text pt-2">
                <p>Recommended</p>
                <p>{recomend}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${recomend * 20}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="copyright-bar">
            © Copyright 2025 Neksogix. All Rights Reserved
          </div>
        </div>
      
    </>
  );
}

export default Footer;
