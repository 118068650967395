import React, { useState, useEffect, } from 'react';
import { IoSearch } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import logo from "../assets/LogowithoutBG.svg";
import { Link } from 'react-router-dom';
import {
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import './Navbar.css';

function Navbaar() {

  const [searchOpen, setSearchOpen] = useState(false);
  const handleSearchMouseEnter = () => {
    setSearchOpen(true);
  };

  const handleSearchMouseLeave = () => {
    setSearchOpen(false);
  };
  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const [isProjectHovered, setIsProjectHovered] = useState(false);

  // Handlers for SERVICES dropdown
  const handleServicesMouseEnter = () => {
    setIsServicesHovered(true);
  };

  const handleServicesMouseLeave = () => {
    setIsServicesHovered(false);
  };

  // Handlers for PROJECT dropdown
  const handleProjectMouseEnter = () => {
    setIsProjectHovered(true);
  };

  const handleProjectMouseLeave = () => {
    setIsProjectHovered(false);
  };
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search/${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top "  >
        <div className="container-fluid pe-0 ps-0">
          <div className='d-flex flex-row align-items-center flex-wrap' >
            <Link className="navbar-brand me-0  " to={"/home"}>
              <img src={logo} width={70} alt="Logo" className='responsive-logo' />
            </Link>
            <div className='title'>
              <h2 className='mb-0 responsive-title ' >NEKSOGIX</h2>
            </div>
          </div>

          <div className="collapse navbar-collapse justify-content-center ms-lg-4 me-lg-4  " id="navbarSupportedContent">
            <ul className={`navbar-nav d-none d-lg-flex ${searchOpen ? 'shrink' : ''}`}>
              <li className="nav-item">
                <Link className="nav-link" to={"/home"}>HOME</Link>
              </li>

              <div className="d-flex align-items-center">
                <li className="nav-item dropdown" onMouseEnter={handleServicesMouseEnter} onMouseLeave={handleServicesMouseLeave}>
                  <Link className={`nav-link ${isServicesHovered ? 'hover-color' : ''}`} to={'services/artificial-intelligence'}>
                    SERVICES
                  </Link>
                  <ul className={`dropdown-menu ${isServicesHovered ? 'show' : ''}`}>
                    <div className="dropdown-grid">
                      <li><Link className="dropdown-item" to={"services/artificial-intelligence"}>Artificial Intelligence</Link></li>
                      <li><Link className="dropdown-item" to={"services/machine-learning"}>Machine Learning</Link></li>
                      {/* <li><Link className="dropdown-item" to={"services/data-mining"}>Data Mining</Link></li> */}
                      <li><Link className="dropdown-item" to={"services/database-management"}>Database Management</Link></li>
                      <li><Link className="dropdown-item" to={"services/web-development"}>Web Application</Link></li>
                      <li><Link className="dropdown-item" to={"services/web-scraping"}>Web Scraping</Link></li>
                      <li><Link className="dropdown-item" to={"services/python-scripting"}>Python Scripting</Link></li>
                      <li><Link className="dropdown-item" to={"services/data-analysis"}>Data Analysis/Visualization</Link></li>
                      {/* <li><Link className="dropdown-item" to={"services/data-visualization"}>Data Visualization</Link></li> */}
                      <li><Link className="dropdown-item" to={"services/web-development"}>Web Development</Link></li>
                      <li><Link className="dropdown-item" to={"services/application-reengineering"}>Application Re-Engineering</Link></li>
                      <li><Link className="dropdown-item" to={"services/custom-software-development"}>Custom Software Development</Link></li>
                      <li><Link className="dropdown-item" to={"services/backend-development"}>Backend Development</Link></li>
                      <li><Link className="dropdown-item" to={"services/ecommerce-store"}>ECommerce Store</Link></li>
                    </div>
                  </ul>
                </li>
              </div>


              <li className="nav-item">
                <Link className="nav-link" to={"/company"}>COMPANY</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/blog"}>BLOGS</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/project"}>PROJECTS</Link>
              </li>
              {/* <li className="nav-item" onMouseEnter={handleProjectMouseEnter} onMouseLeave={handleProjectMouseLeave}> */}
                {/* <div className="d-flex align-items-center"> */}

                  {/* <Link className={`nav-link ${isProjectHovered ? 'hover-color' : ''}`} to={'project/ecommerceproject'}>
                    PROJECT
                  </Link>
                  <ul className={`dropdown-menu ${isProjectHovered ? 'show' : ''}`}>
                    <div className="dropdown">
                      <li><Link className="dropdown-item" to={"project/iProperty"}>iProperty Price Checker System</Link></li>
                      <li><Link className="dropdown-item" to={"project/ipropertyproject"}>iProperty Price Checker</Link></li>
                    </div>
                  </ul>*/}
                  {/* </div>  */}
              {/* </li> */}

              <li className="nav-item">
                <Link className="nav-link" to={"/contact"}>CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className='d-lg-flex justify-content-lg-center align-items-center'>
            <div className='d-flex align-items-center'>
              <form className={`search-container ${searchOpen ? 'expanded' : ''}`}
                onMouseEnter={handleSearchMouseEnter}
                onMouseLeave={handleSearchMouseLeave} onSubmit={handleSearch}>
                <i className='search-icon pe-lg-2' onClick={toggleSearch}><IoSearch /></i>
                <input
                  type="text"
                  className="search-input me-3 ms-0"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onBlur={() => setSearchOpen(false)}
                />
              </form>

              <button className="navbar-toggler me-0 me-md-3 me-lg-3 border-0" type="submit" onClick={onOpen}>
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <Link to={"/book-a-call"}><button className="call-btn me-3 d-none d-lg-block">
              <span>Schedule Strategy Call</span>
            </button></Link>

            {/* <Link to={"/login"}> <button className="login-btn me-3 d-none d-lg-block">
              <span>Login</span>
            </button> </Link> */}

          </div>

        </div>
      </nav >
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <ul className="drawer-nav" style={{ marginTop: 40 }}>
              <li className="drawer-nav-item">
                <Link className="drawer-nav-link" aria-current="page" to={"/home"}>HOME</Link>
              </li>
              <li
                className="drawer-nav-item drawer-dropdown"
                onMouseEnter={handleServicesMouseEnter}
                onMouseLeave={handleServicesMouseLeave}
              >
                <Link className={`drawer-nav-link ${isServicesHovered ? 'drawer-hover-color' : ''}`} to={"services/artificial-intelligence"}>SERVICES</Link>
                <ul className={`drawer-dropdown-menu ${isServicesHovered ? 'drawer-show' : ''}`}>
                  <li><Link className="drawer-dropdown-item" to={"services/web-development"}>Web Development</Link></li>
                  {/* <li><Link className="drawer-dropdown-item" to={"services/data-visualization"}>Data Visualization</Link></li> */}
                  <li><Link className="drawer-dropdown-item" to={"services/ecommerce-store"}>ECommerce Store</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/python-scripting"}>Python Scripting</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/machine-learning"}>Machine Learning</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/artificial-intelligence"}>Artificial Intelligence</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/database-management"}>Database Managemnet</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/web-development"}>Web Application</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/backend-development"}>Backend Development</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/data-analysis"}>Data Analysis/Visualization </Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/data-mining"}>Data Mining</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/application-reengineering"}>Application Re-Engineering</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/custom-software-development"}>Custom Software Development</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"services/web-scraping"}>Web Scraping</Link></li>
                </ul>
              </li>
              <li className="drawer-nav-item">
                <Link className="drawer-nav-link" aria-current="page" to={"/company"}>COMPANY</Link>
              </li>
              <li className="drawer-nav-item">
                <Link className="drawer-nav-link" to={"/blog"}>BLOGS</Link>
              </li>
              <li className="drawer-nav-item">
                <Link className="drawer-nav-link" to={"/project"}>PROJECTS</Link>
              </li>
              {/* <li
                className="drawer-nav-item drawer-dropdown"
                onMouseEnter={handleProjectMouseEnter}
                onMouseLeave={handleProjectMouseLeave}
              >
                <Link className={`drawer-nav-link ${isProjectHovered ? 'drawer-hover-color' : ''}`} to={"project/ecommerceproject"}>PROJECT</Link>
                <ul className={`drawer-dropdown-menu ${isProjectHovered ? 'drawer-show' : ''}`}>
                  <li><Link className="drawer-dropdown-item" to={"project/iProperty"}>iProperty Price Checker System</Link></li>
                  <li><Link className="drawer-dropdown-item" to={"project/ipropertyproject"}>iProperty Price Checker</Link></li>
                </ul>
              </li> */}
              <li className="drawer-nav-item">
                <Link className="drawer-nav-link" to={"/contact"}>CONTACT US</Link>
              </li>
              {/* <div className="drawer-nav-item">
                <Link to={"/login"}> <button className="drawer-login-button">Login</button> </Link>
              </div> */}
              <div className="drawer-nav-item">
                <Link to={"/book-a-call"}><button className="drawer-login-button">
                  <span>Schedule Strategy Call</span>
                </button></Link>
              </div>
            </ul>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navbaar;
